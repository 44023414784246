import React, { useState, useEffect } from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { Text } from "../Type";
import { Tertiary } from "../Button";
import { TextInput } from "../Inputs";
import { Comment } from "../Icons";
import NextLine from "./NextLine";
import { useAuditLogs } from "../../hooks/useAuditLogs";
import getPriorityLevel from "../../helpers/getPriorityLevel";
import { MILLISECONDS_IN_SECONDS } from "../../helpers/constants";
import styles from "./index.module.scss";
import DateTime from "../DateTime";
import { logErrorToLogdna } from "../../helpers/logdna";
import { useAuth } from "../../contexts/AuthProvider";

const MIN_LOG_ENTRY_CHARACTERS = 3;
const MAX_LOG_ENTRY_CHARACTERS = 200;
const ERROR_CODES = {
  savingComment: "SAVING_COMMENT"
};

const validateLogEntry = (log: string): string => {
  let errorMessage = "";
  if (log.length < MIN_LOG_ENTRY_CHARACTERS) {
    errorMessage = `Please enter at least ${MIN_LOG_ENTRY_CHARACTERS} characters`;
  }

  if (log.length > MAX_LOG_ENTRY_CHARACTERS) {
    errorMessage = "Log entry too large";
  }

  return errorMessage;
};

type Props = {
  auditLogs: Array<gqlType.EventAuditLog>;
  type: string;
  id: string;
  statusPriority?: string;
};

const CommentBox = ({ auditLogs, type, id, statusPriority }: Props) => {
  const { saveLog, saveLogLoading, saveLogError } = useAuditLogs();
  const [logInput, setLogInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const eventPriority = statusPriority || getPriorityLevel(type);
  const { permissions } = useAuth();

  // Handle save log state changes
  useEffect(() => {
    // on save failure
    if (saveLogError && !saveLogLoading) {
      const message = "Error saving comment. Please try again.";
      setErrorMessage(message);
      logErrorToLogdna(ERROR_CODES.savingComment, message);
    }

    // on save success
    if (!saveLogError && !saveLogLoading) {
      setLogInput("");
    }
  }, [saveLogLoading, saveLogError]);

  const attemptSaveLog = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // clear previous error message
    setErrorMessage("");

    const errorMessage = validateLogEntry(logInput);
    if (errorMessage) {
      setErrorMessage(errorMessage);
    } else if (!event) {
      setErrorMessage("No event data currently available. Try again.");
    } else {
      saveLog(logInput, id);
    }
  };

  const handleLogInput = (value: string) => {
    setLogInput(value);
    setErrorMessage("");
  };

  return (
    <div className={styles.Wrapper}>
      {!permissions.user["read-only-access"] ? (
        <form className={styles.LogForm} onSubmit={attemptSaveLog}>
        <TextInput
          onChange={handleLogInput}
          value={logInput}
          placeholder="Add a comment"
          errorMessage={errorMessage}
          label="Add a comment"
          size="small"
        />
        <Tertiary
          buttonSize="small"
          className={styles.SendButton}
          type="submit"
        >
          Submit
        </Tertiary>
        </form>
        ) : null
      }
      <div className={styles.LogFade}>
        <div className={styles.LogScroll}>
          <div className={styles.LogList}>
            {auditLogs &&
              auditLogs.map(log => {
                const isSystemLog = log.comment.systemCreated;
                const isCreatedLog =
                  log.comment.type.toLowerCase() === "event created";
                const isUpdatedLog =
                  log.comment.type.toLowerCase() === "event updated";
                const isCommentLog =
                  log.comment.type.toLowerCase() === "comment";

                return (
                  <div key={log.id} className={styles.LogEntry}>
                    {isCommentLog ? (
                      <>
                        <span
                          className={cx(
                            styles.LogEntryIcon,
                            styles.LogEntryIconComment
                          )}
                        >
                          <Comment size={8} />
                        </span>
                        <Text size="small" component="span">
                          {log.comment.message}
                        </Text>
                      </>
                    ) : (
                      <>
                        <span
                          className={cx(styles.LogEntryIcon, {
                            [styles.LogEntryIconSystem]:
                              !isCreatedLog && isSystemLog,
                            [styles.LogEntryIconLow]:
                              isCreatedLog && eventPriority === "low",
                            [styles.LogEntryIconMedium]:
                              isCreatedLog && eventPriority === "medium",
                            [styles.LogEntryIconHigh]:
                              isCreatedLog && eventPriority === "high",
                            [styles.LogEntryIconComment]:
                              isCommentLog && !isSystemLog
                          })}
                        />
                        <Text size="small" component="span">
                          {isUpdatedLog
                            ? log.comment.message
                            : log.comment.type}
                        </Text>
                        {!isUpdatedLog && log.comment.message && (
                          <Text size="small" component="span">
                            <NextLine className={styles.NextLine} />{" "}
                            {log.comment.message}
                          </Text>
                        )}
                      </>
                    )}

                    <Text size="small" component="span" dark>
                      {log.comment.author && !isSystemLog
                        ? `${log.comment.author} - `
                        : ""}
                      <DateTime
                        timestamp={log.createdAt * MILLISECONDS_IN_SECONDS}
                        length="long"
                      />
                    </Text>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentBox;
